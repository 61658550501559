<template>
  <b-modal
    id="booking-confirmation-modal"
    title="Booking Confirmed" 
    v-model="isBookingModalVisible"
    hide-footer
    centered
     size="lg"
    :no-close-on-esc="true" 
    :no-close-on-backdrop="true" 
    @hidden="onModalClosed"
  >
    <div class="booking-modal-content">
      <div class="booking-details">
        <h4 class="font-weight-bold Details"><strong>BOOKING DETAILS</strong></h4>
        <p><strong>Check-in Date:</strong><br> {{ formattedCheckInDate }}</p>
        <p><strong>Check-out Date:</strong> <br>{{ formattedCheckOutDate }}</p>
        <p><strong>Booking Created:</strong> <br>{{ formattedCreationDate }}</p>
      <div class="mt-md-5 border-primary rounded p-md-2 p-1 new-guide">
        <h4 class="text-center" style="color:#f6e3cc"><strong>Payment Guidelines</strong></h4>
        <ul class="font-weight-bold">
          <li>Log In To Your Mobile Banking App</li>
          <li>Choose "1Bill Invoice" from Bill Payment Options</li>
          <li>Enter Voucher No and follow instructions to complete payment</li>
        </ul>
      </div>
      </div>
      <div class="booking-timer py-md-2 ">
        <h4 class="pt-md-4 pt-2" style="color:#f6e3cc">TIME LEFT</h4>
        <p class="timer-note pt-2">Your booking has been saved temporarily and will be cancelled if advance payment is not deposited by expiry time.</p>
        <div class="timer py-md-3 py-1">
          <span class="timer-value">{{ minutesLeft  }}</span>
          <span class="timer-separator">:</span>
          <span class="timer-value">{{ secondsLeft  }}</span>
        </div>
        <div class="py-md-3 py-1" style="border-top: 2px solid white;">
          <p class="font-weight-bold" style="color:#f6e3cc">Voucher No:</p>
          <div class="d-flex justify-content-center align-items-center">
            <p class="text-white ml-3 mr-50 mb-0 text-center">786786{{ bookingDetails.voucher_no }}</p>
            <feather-icon icon="CopyIcon" class="copy-icon" style="width: 20px; height: 20px;" @click="copyToClipboard"/>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    isBookingModalVisible: Boolean,
    bookingDetails: Object,
    globalPolicies: Object
  },
  data() {
    return {
      computedTimeLeft: '',
      updateInterval: null,
      serverTime: null,
      currentTime: null,
      remainingTime: 0,
      updateInterval: null,
      bookingCreationTime: null,
      bookingExpiryTime: null,
    };
  },
  created() {
    // this.initializeTimer();
  },
  async mounted() {
    if(this.bookingDetails.created_at){
      await this.fetchSystemTime()
      var systemDateTime  = await this.getSystemTime
      this.serverTime = new Date(systemDateTime);
      // this.bookingCreationTime = new Date(this.bookingDetails.created_at + ' +05:00');

      let dateStringISO8601 = this.bookingDetails.created_at + ' +05:00'
      let parts = dateStringISO8601.split(' ');
      let datePart = parts[0]; // '2024-07-05'
      let timePart = parts[1]; // '00:00:00'
      let offsetPart = parts[2]; // '+05:00'
      let formattedDateString = datePart + 'T' + timePart + offsetPart;
      this.bookingCreationTime = new Date(formattedDateString);

      this.bookingExpiryTime = new Date(this.bookingCreationTime.getTime() + (parseInt(this.globalPolicies.booking_validity) * 60000));
      this.currentTime = new Date(systemDateTime)
      this.updateInterval = setInterval(() => {
        this.remainingTime = Math.floor((this.bookingExpiryTime.getTime() - this.currentTime.getTime()) / 1000);
        this.currentTime = new Date(this.currentTime.getTime() + 1000)
      }, 1000);
    }
  },
  computed: {
    ...mapGetters({
      getSystemTime: "appData/getSystemTime",
    }),
    formattedCheckInDate() {
      return moment(this.bookingDetails.start_date_time).format('DD-MM-YYYY HH:mm:ss');
    },
    formattedCheckOutDate() {
      return moment(this.bookingDetails.end_date_time).format('DD-MM-YYYY HH:mm:ss');
    },
    formattedCreationDate() {
      return moment(this.bookingDetails.created_at).format('DD-MM-YYYY HH:mm:ss');
    },
   
    minutesLeft() {
      const minutes = Math.floor(this.remainingTime / 60);
      return minutes >= 0 ? String(minutes).padStart(2, '0') : '00';
    },
    secondsLeft() {
      const seconds = this.remainingTime % 60;
      return seconds >= 0 ? String(seconds).padStart(2, '0') : '00';
    },
  
  },
  methods: {
    ...mapActions({
        fetchSystemTime: "appData/fetchSystemTime",
    }),
     onModalClosed() {
      this.$router.push({ name: "GuestHouses" });
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText("786786"+this.bookingDetails.voucher_no);
      } catch (error) {
        console.error('Error copying to clipboard:', error);
      }
    }
  },


beforeDestroy() {
  if (this.updateInterval) {
    clearInterval(this.updateInterval);
  }
}
};
</script>
<style>
.booking-modal-content {
  display: flex;
  background: url('~@/assets/booking/popup.png') no-repeat center center;
  background-size: cover;
  color: #fff; 
  border-radius: 10px; 
  height: auto; 
}

.booking-details, .booking-timer {
  flex: 1; 
}

@media (max-width: 768px) {
  .booking-modal-content {
    flex-direction: column; 
  }

  .booking-details, .booking-timer {
 
  }
  
  .booking-timer {
    background: none;
  }
}

.booking-details {
  padding: 20px; 
}

.booking-timer {
  text-align: center;
  padding-inline: 25px;
  background: url('~@/assets/booking/timer.png') no-repeat center/cover;
}

.booking-details {
  display: flex;
  flex-direction: column;
}

.booking-details p {
  margin-bottom: 10px;
}

.booking-details p strong {
  margin-right: 10px; 
  display: inline-block; 
}

.booking-details p:not(:first-child) strong {
  margin-top: 10px; 
}


.booking-timer {
  text-align: center;
  padding-inline: 25px;
  background: url('~@/assets/booking/timer.png') no-repeat center/cover;
}
.new-guide{
  
  background: url('~@/assets/booking/timer.png') no-repeat center/cover;
}

h5 {
  margin-bottom: 20px; 
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.timer-value {
  font-size: 3rem; 
  font-weight: bold;
  line-height: 1;
  color: black;
  background: white;
  padding-inline: 10px;
  padding-block: 18px;
  border-radius: 5px;
}

.timer-separator {
  font-size: 3rem; 
  margin: 0 10px; 
}

.timer-note {
  font-size: 0.875rem; 
  color: white;
  font-size: 14px;
  text-align: left;
}

p {
  font-size: 15px; 
  color: black;
}
.Details{
  font-size: 25px;
  font-weight: bolder;
}
.copy-icon{
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.copy-icon:hover{
   transform: scale(1.2);
}
</style>
