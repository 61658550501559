<template>
  <div>
    <b-modal
      id="booking-create-modal"
      title="Book Guest Room"
      centered
      hide-footer
      size="lg"
      @hidden="resetModal"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <template v-if="isMounted">
        <div v-if="!summary">
          <validation-observer ref="bookingFormValidation">
            <b-form class="auth-register-form mt-1" @submit.prevent>
              <div class="px-1 mx-1">
                <b-row>
                  <b-col md="4" class="px-2 py-2">
                    <b-form-group
                      label="Check-In Date"
                      label-class="font-weight-bolder"
                    >
                      <b-form-input
                        disabled
                        v-model="checkInDate"
                        type="date"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" class="px-2 py-2">
                    <b-form-group
                      label="Check-Out Date"
                      label-class="font-weight-bolder"
                    >
                      <b-form-input
                        disabled
                        v-model="checkOutDate"
                        type="date"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" class="px-2 py-2">
                    <validation-provider
                      #default="{ errors }"
                      name="number"
                      rules="required|integer|length:11"
                    >
                      <b-form-group
                        label="Number"
                        label-class="font-weight-bolder"
                      >
                        <b-form-input
                          id="number"
                          v-model="number"
                          :state="errors.length > 0 ? false : null"
                          name="mobile"
                          placeholder="0310XXXXXXX"
                          :disabled="self.includes('Self')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </div>
              <div class="px-1 mx-1">
                <b-row>
                  <b-col md="4" class="px-2 py-2">
                    <b-form-group
                      label="Booking For"
                      label-class="font-weight-bolder"
                    >
                      <v-select
                        id="self"
                        v-model="self"
                        name="self"
                        :options="selfOptions"
                        placeholder="Booking For"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" class="px-2 py-2">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      :rules="{ required, regex: /^[A-Za-z\s]{1,50}$/ }"
                    >
                      <b-form-group
                        label="Name"
                        label-class="font-weight-bolder"
                      >
                        <b-form-input
                          id="name"
                          v-model="name"
                          :state="errors.length > 0 ? false : null"
                          name="name"
                          maxlength="50"
                          placeholder="Name"
                          :disabled="self.includes('Self')"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="4" class="px-2 py-2">
                    <validation-provider
                      #default="{ errors }"
                      name="CNIC"
                      rules="required|integer|length:13"
                    >
                      <b-form-group
                        label="Cnic"
                        label-class="font-weight-bolder"
                      >
                        <b-form-input
                          id="cnic"
                          v-model="cnic"
                          :state="errors.length > 0 ? false : null"
                          name="cnic"
                          placeholder="61101XXXXXXXX"
                          :disabled="self.includes('Self')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="px-2 py-2 d-flex justify-content-center"
                  >
                    <b-form-row v-if="self.includes('Self') && this.getLoggedInUser.user_type == this.userTypes.SERVING">
                      <validation-provider
                        #default="{ errors }"
                        name="Duty Status"
                      >
                        <b-form-radio-group
                          id="onDutyStatus"
                          v-model="onDutyStatus"
                          name="onDutyStatus"
                        >
                          <b-form-radio :value="true" class="md-6 mr-5"
                            >On Duty</b-form-radio
                          >
                          <b-form-radio :value="false" class="md-6"
                            >On Leave</b-form-radio
                          >
                        </b-form-radio-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-row>
                  </b-col>
                </b-row>
                <b-row class="ml-3 mt-2">
                  <p
                    v-if="onDutyStatus"
                    class="text-danger border-danger rounded p-2 align-items-center"
                  >
                    <feather-icon
                      icon="AlertTriangleIcon"
                      size="18"
                      class="mr-1"
                    />
                    To avail full refund wrt On Duty Cancellation, the offr
                    concerned must process the case on OAS with AGM Cell, SD Dte
                    providing all necessary details/ documentss wrt duty/
                    cancellation of duty
                  </p>
                </b-row>
              </div>
              <div>
                <div v-for="(room, index) in rooms" :key="index">
                  <validation-provider
                    #default="{ errors }"
                    name="Room Type"
                    rules="required"
                  >
                    <b-form-group
                      class="d-flex justify-content-center align-items-center py-2"
                    >
                      <h3 class="text-center">Room Type</h3>
                      <div class="d-flex py-1">
                        <b-form-radio
                          class="px-1"
                          v-for="(roomType, i) in roomTypes"
                          :key="i"
                          v-model="room.type"
                          :value="roomType.room_type.id"
                          style="width: 100%"
                          :disabled="
                            availableRooms.rooms_by_type.filter(
                              (r) =>
                                r.count === 0 &&
                                r.room_type === roomType.room_type.id
                            ).length > 0 ||
                            rooms.filter(
                              (r) => r.type === roomType.room_type.id
                            ).length >=
                              parseInt(
                                availableRooms.rooms_by_type.filter(
                                  (r) => r.room_type === roomType.room_type.id
                                )[0]['count']
                              )
                          "
                        >
                          <div class=" ">
                            <span class="px-1">{{
                              roomType.room_type.name
                            }}</span>
                          </div>
                        </b-form-radio>
                      </div>
                    </b-form-group>
                    <small class="text-danger d-flex justify-content-center">{{
                      errors[0]
                    }}</small>
                  </validation-provider>

                  <b-card v-if="room.type" class="mb-3">
                    <b-row>
                      <b-col cols="6">
                        <!-- <img
                          :src="getImagesForRoom(room.type).path"
                          style="height: 100%; width: 100%; border-radius: 25px"
                        /> -->
                        <div>
                          <b-carousel
                            id="carousel-fade"
                            fade
                            indicators  
                            img-width="0"
                            img-height="280"
                            >
                            <b-carousel-slide
                              v-for="(roomImage, index) in getRoomImages(room.type)" :key="index"
                              :img-src="roomImage.path"
                            >
                            </b-carousel-slide>
                          </b-carousel>
                        </div>
                      </b-col>
                      <b-col cols="6">
                        <div>
                          <h2
                            class="border rounded d-flex justify-content-center"
                          >
                            Price: {{ getPriceForRoom(room.type) }}
                          </h2>
                          <div class="mb-2">
                            <strong>Facilities:</strong>
                            <div
                              class="ml-1 mb-2 mt-1"
                              v-for="facility in getFacilitiesForRoom(room.type)"
                              :key="facility.id"
                            >
                              <img
                                :src="facility.icon"
                                alt="Facility Icon"
                                class="mr-1"
                                style="height: 20px; width: 20px"
                                v-b-tooltip.hover
                                :title="facility.name"
                              />
                              <strong>{{ facility.name }}</strong>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                  <div class="d-flex justify-content-end mb-5">
                    <b-button
                      v-if="rooms.length > 0"
                      @click="removeRoom(index)"
                      variant="outline-danger"
                      >Remove Room</b-button
                    >
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <b-button
                  v-if="showAddButton"
                  @click="addRoom"
                  variant="primary"
                >
                  Add Room
                </b-button>
              </div>

              <b-row class="d-flex justify-content-center px-2 py-2">
                <b-button
                  @click="validateform"
                  variant="primary"
                  class="pl-2 pr-2"
                  :disabled="proceedDisabled"
                >
                  Proceed To Confirmation
                </b-button>
              </b-row>
            </b-form>
          </validation-observer>
        </div>

        <div class="bold-text" v-else>
          <h1 class="text-center">Summary</h1>
          <hr />
          <h4>
            Name: <strong>{{ this.name }}</strong>
          </h4>

          <h4>
            CNIC: <strong>{{ this.cnic }}</strong>
          </h4>
          <h4>
            Mobile Number: <strong>{{ this.number }}</strong>
          </h4>
          <h4 v-if="bookingFor">
            Booking For: <strong>{{ bookingFor ? bookingFor.name : ''}}</strong>
          </h4>
          <h4 v-if="self.includes('Self') && this.onDutyStatus">
            On Duty: <feather-icon icon="CheckIcon" class="mr-50" />
          </h4>
          <hr />
          <hr />
          <h3>Room Details:</h3>
          <ul v-if="rooms.length > 0">
            <li v-for="(room, index) in rooms" :key="index">
              Room Type: <b>1 x</b> {{ getRoomTypeName(room.type) }}
              <br />
              - Price: {{ getPriceForRoom(room.type) }}
              <br />
              <span v-if="getFacilitiesForRoom(room.type).length > 0">
                - Facilities:
                <ul>
                  <li
                    v-for="facility in getFacilitiesForRoom(room.type)"
                    :key="facility.id"
                  >
                    {{ facility.name }}
                  </li>
                </ul>
              </span>
              <hr />
            </li>
            <li>Total Rooms: <b>{{ rooms.length }}</b></li>
          </ul>
          <hr />
          <h4>
            Check-in Date: <strong>{{ checkInDate }} </strong>
          </h4>
          <h4>
            Check-out Date: <strong>{{ checkOutDate }} </strong>
          </h4>
          <hr />
          <h4>
            <strong> Total: {{ totalPayment }} </strong>
          </h4>
          <hr />
          <div
            class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
          >
            <div
              class="d-flex justify-content-center align-items-center flex-nowrap mr-2"
            >
              <b-button @click="back" variant="secondary" class="pl-2 pr-2"
                >Back</b-button
              >
            </div>

            <div
              class="d-flex justify-content-center align-items-center flex-nowrap mr-2"
            >
              <b-button @click="bookRoom" variant="primary" class="pl-2 pr-2"
                >Confirm</b-button
              >
            </div>
            <div
              class="d-flex justify-content-center align-items-center flex-nowrap mr-2"
            >
              <b-button @click="cancel" variant="danger" class="pl-2 pr-2"
                >Cancel</b-button
              >
            </div>
          </div>
        </div>
        <b-overlay :show="show" spinner-variant="primary" no-wrap />
        <b-overlay :show="showAlertOverlay" no-wrap>
          <template #overlay>
            <div class="text-center">
              <div>
                <div class="my-3 p-2">
                  <feather-icon icon="AlertTriangleIcon" size="60" class="mr-1 mt-5"/>
                  <h1 class="alert-overlay-text-size" style="color: red !important">ALERT</h1>
                  <br>
                  <hr>
                  <hr>
                  <h1 style="color: red !important">Please read Cancellation and Early Checkout Policies carefully before proceeding further, since it has financial implications.</h1>
                  <br>
                  <hr>
                  <hr>
                </div>
                <b-button
                  ref="alertOverlayOkayButton"
                  variant="primary"
                  size="lg"
                  @click="showAlertOverlay = false"
                  class="mb-3"
                >
                  Okay
                </b-button>
              </div>
            </div>
          </template>
        </b-overlay>
      </template>
    </b-modal>

    <booking-confirmation-modal
      @modalClosed="isModalVisible = false"
      :isBookingModalVisible="isModalVisible"
      :bookingDetails="bookingDetails"
      :globalPolicies="globalPolicies"
      :key="`view-${BookingConfirmationModalCount}`"
    />
  </div>
</template>
  
    <script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { mapActions, mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";
import util from "@/util.js";
import moment from "moment";
import BookingConfirmationModal from "@/components/booking/admin/BookingConfirmationModal.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BookingConfirmationModal,
  },
  props: {
    roomTypes: Array,
    messData: Object,
    availableRooms: Object,
    roomImages: Array,
  },
  mixins: [util],
  data() {
    return {
      show: false,
      isModalVisible: false,
      showModal: false,
      checkInDate: "",
      checkOutDate: "",
      name: "",
      cnic: "",
      number: "",
      img: null,
      currentRoomTypeImages: null,
      numOfRoom: null,
      numOfRooms: [1, 2],
      self: null,
      selfOptions: ["Self", "Civil"],
      summary: false,
      computedTimeLeft: "",
      isMounted: false,
      filters: null,
      required,
      bookingDetails: {},
      globalPolicies: {},
      BookingConfirmationModalCount: 0,
      visible: false,
      onDutyStatus: false,
      totalPayment: 0,
      selectedRooms: [],
      rooms: [{ type: null }],
      showAlertOverlay: false,
      bookingFor: null
    };
  },
  async created() {
    try {
      const storedFilters = localStorage.getItem("globalFilters");
      if (storedFilters) {
        const filtersDict = JSON.parse(storedFilters);
        if (filtersDict && typeof filtersDict === "object") {
          this.filters = filtersDict;
          localStorage.setItem("globalFilters", JSON.stringify(this.filters));

          if (this.filters.date_from) {
            this.checkInDate = this.filters.date_from;
          }
          if (this.filters.date_to) {
            this.checkOutDate = this.filters.date_to;
          }
          if (this.filters.booking_for && this.filters.booking_for.name) {
            for(let i=0; i<this.selfOptions.length; i++){
              if(this.filters.booking_for.name.includes(this.selfOptions[i])){
                this.self = this.selfOptions[i];
                this.bookingFor = this.filters.booking_for
              }
            }
          }
        } else {
          console.error("filtersDict is null or undefined");
        }
      } else {
        console.error("storedFilters is null or undefined");
      }
    } catch (error) {
      console.error("Error fetching global filters:", error);
    }
  },

  async mounted() {
    await this.fetchGlobalPoliciesState();
    this.globalPolicies = this.getGlobalPoliciesState
    this.isMounted = true;
  },
  methods: {
    ...mapActions({
      createBooking: "appData/createBooking",
      fetchGlobalPoliciesState: "appData/fetchGlobalPoliciesState",
      calculateBookingPayment: "appData/calculateBookingPayment",
    }),
    addRoom() {
      this.rooms.push({ type: null });
    },
    removeRoom(index) {
      this.rooms.splice(index, 1);
    },
    getRoomTypeName(roomType) {
      const selectedRoom = this.roomTypes.find(
        (rt) => rt.room_type.id === roomType
      );
      return selectedRoom ? selectedRoom.room_type.name : "";
    },
    getPriceForRoom(roomType) {
      const selectedRoom = this.roomTypes.find(
        (rt) => rt.room_type.id === roomType
      );
      return selectedRoom ? selectedRoom.price : "";
    },
    getFacilitiesForRoom(roomType) {
      const selectedRoomTypeDetail = this.roomTypes.find(
        (rt) => rt.room_type.id === roomType
      );
      if (selectedRoomTypeDetail && selectedRoomTypeDetail.room_type) {
        return selectedRoomTypeDetail.room_type.facilities;
      }
      return [];
    },
    // getImagesForRoom(roomType) {
    //   const selectedRoomType = this.roomImages.filter(
    //     (rt) => rt.room_type === roomType
    //   );
    //   return selectedRoomType;
    // },
    resetModal() {},
    showSummary() {
      this.summary = true;
    },
    back() {
      this.summary = false;
    },
    cancel() {
      this.$emit("modalClosed");
    },
    async bookRoom() {
      await this.submit();
    },
    async validateform() {
      const success = await this.$refs.bookingFormValidation.validate();
      if (success) {
        await this.calculatePayment();
        this.showAlertOverlay = true;
        this.showSummary();
      }
    },
    async onModalClosed() {
      this.visible = false;
      this.$router.push({ name: "GuestHouses" });
    },

    async calculatePayment() {
      let selectedRooms = [];
      this.rooms.forEach((room) => {
        if (room.type) {
          selectedRooms.push({
            room_type: room.type,
            count: 1,
          });
        }
      });
      const storedFilters =
        JSON.parse(localStorage.getItem("globalFilters")) || {};
      let bookingFor = 1;
      if (Object.keys(storedFilters).length !== 0) {
        if ("booking_for" in storedFilters) {
          bookingFor = storedFilters.booking_for;
        }
      }
      const bookingForCategory =
        bookingFor.id == 1 ? this.getLoggedInUser.user_type : 3;

      let params = {
        mess: this.messData.id,
        room_types: selectedRooms,
        date_from: moment(this.checkInDate)
          .set({ hour: 16, minute: 0, second: 0 })
          .format("YYYY-MM-DD HH:mm:SS"),
        date_to: moment(this.checkOutDate)
          .set({ hour: 12, minute: 0, second: 0 })
          .format("YYYY-MM-DD HH:mm:SS"),
        booking_for_category: bookingForCategory,
      };
      const res = await this.calculateBookingPayment(params);
      this.totalPayment = res.data;
    },

    async submit() {
      try {
        this.show = true;
        let selectedRooms = [];
        this.rooms.forEach((room) => {
          if (room.type) {
            selectedRooms.push({
              room_type: room.type,
              count: 1,
            });
          }
        });
        const storedFilters =
          JSON.parse(localStorage.getItem("globalFilters")) || {};
        let bookingFor = 1;
        if (Object.keys(storedFilters).length !== 0) {
          if ("booking_for" in storedFilters) {
            bookingFor = storedFilters.booking_for;
          }
        }
        else{
          return
        }
        const bookingForCategory =
          bookingFor.id == 1 ? this.getLoggedInUser.user_type : 3;

        let dataToInsert = {
          customer_id: this.getLoggedInUser.id,
          mess: this.messData.id,
          booking_room_types: selectedRooms,
          start_date_time: moment(this.checkInDate)
            .set({ hour: 16, minute: 0, second: 0 })
            .format("YYYY-MM-DD HH:mm:SS"),
          end_date_time: moment(this.checkOutDate)
            .set({ hour: 12, minute: 0, second: 0 })
            .format("YYYY-MM-DD HH:mm:SS"),

          total_payment: this.totalPayment,

          booking_for: bookingFor.id,
          booking_for_category: bookingForCategory,
          created_by: this.getLoggedInUser.id,
          updated_by: this.getLoggedInUser.id,
          booking_flow: 2,
        };
        if (bookingFor.id == 2) {
          dataToInsert["guest_data"] = {
            name: this.name,
            cnic: this.cnic,
            mobile: this.number,
          };
        }
        if(this.onDutyStatus){
          dataToInsert["on_duty"] = this.onDutyStatus          
        }
        const res = await this.createBooking(dataToInsert);
        if (res.status === 201) {
          this.show = false;
          this.BookingConfirmationModalCount += 1;
          this.isModalVisible = true;
          this.bookingDetails = res.data;

          if (this.BookingConfirmationModalCount == 1) {
            this.resetModal();
            this.$emit("modalClosed");
          }
        }
      } catch (error) {
        this.displayError(error);
        this.resetModal();
        this.onModalClosed()
      }
    },
    getRoomImages(roomType) {
      const roomImages = this.roomImages.filter(
        (rt) => rt.room_type === roomType
      );
      return roomImages;
    },
    formatNumber(number) {
      return number < 10 ? `0${number}` : number;
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
      getUser: "appData/getUser",
      getGlobalPoliciesState: "appData/getGlobalPoliciesState",
    }),
    areAllAvailableRoomsSelected() {
      var nonZeroRoomType = 0;
      var count = 0;

      for (let i = 0; i < this.roomTypes.length; i++) {
        if (
          this.availableRooms.rooms_by_type.filter(
            (r) =>
              r.count === 0 && r.room_type === this.roomTypes[i].room_type.id
          ).length > 0
        ) {
          continue;
        }
        nonZeroRoomType++;
        if (
          this.rooms.filter((r) => r.type === this.roomTypes[i].room_type.id)
            .length >=
          parseInt(
            this.availableRooms.rooms_by_type.filter(
              (r) => r.room_type === this.roomTypes[i].room_type.id
            )[0]["count"]
          )
        ) {
          count++;
        }
      }
      if (count == nonZeroRoomType) {
        return true;
      }
      return false;
    },
    totalAvailableRoomsInMess(){
      if(this.availableRooms)
      {
        return this.availableRooms.total
      }
    },
    proceedDisabled(){
      if(
        !(this.rooms.length > 0) 
        ||
        this.rooms.filter((r) => r.type === null).length > 0
      ){
        return true
      }
      return false
    },
    showAddButton(){
      if (
        !(this.totalAvailableRoomsInMess > this.rooms.length)
        ||
        !(this.rooms.length < parseInt(this.getGlobalPoliciesState.max_rooms_per_user))
        ||
        this.areAllAvailableRoomsSelected
      ) {
        return false
      }
      return true
    }
  },
  watch: {
    self(newValue, oldValue) {
      if (!(newValue.includes('Self'))) {
        this.name = "";
        this.number = "";
        this.cnic = "";
      } else {
        this.name = this.getLoggedInUser.name;
        this.number = this.getLoggedInUser.mobile;
        this.cnic = this.getLoggedInUser.cnic;
      }
    },
  },
};
</script>
<style scoped>
.alert-overlay-text-size {
    font-size: 30px;
}
</style>