<template>
  <div class="guesthouse-page bg-white">
    <b-row class="pt-2 pb-2">
      <b-col md="2"> </b-col>
      <b-col md="8" class="guesthouse-card" v-if="isMounted">
        <!-- Main Information Card -->
        <b-card class="mx-1">
          <b-row>
            <b-col md="6">
              <div v-for="(image, i) in messData.images" :key="i">
                <img
                  :src="image.path"
                  alt="Guesthouse Image"
                  class="img-fluid mb-3 rounded"
                />
              </div>
            </b-col>
            <b-col md="6">
              <div>
                <h1 class="mt-2">
                  <strong>{{ messData.name }}</strong>
                </h1>
                <h5>
                  <strong>Address:&nbsp;</strong
                  >{{ messData.location.address }}, {{ messData.city.name }}
                </h5>
                <h5>
                  <strong>Contact&nbsp;No:&nbsp;</strong>{{ messData.phone_no }}
                </h5>
              </div>
              <div>
                <p>{{ messData.description }}</p>
              </div>
            </b-col>
          </b-row>

          <!-- <div>
            <b-row
              no-gutters
              class="d-flex justify-content-center align-items-center flex-nowrap mb-1 px-5 pt-5"
            >
              <p>{{ messData.description }}</p>
            </b-row>
          </div> -->
        </b-card>

        <h4 class="pt-2 pl-2 pr-5"><strong>Room Types</strong></h4>
        <div class="d-flex justify-content-center">
          <b-row class="w-100" gutters>
            <b-col
              v-for="(room_type_detail, i) in messData.room_type_details"
              :key="i"
              class=""
              lg="4"
              md="6"
              cols="12"
            >
              <b-card-body class="card w-90">
                <h5 class="font-weight-bold mt-1">
                  <strong>{{ room_type_detail.room_type.name }}</strong>
                </h5>
                <template
                  v-if="(messData.no_of_available_rooms.rooms_by_type.find((room) =>room.room_type === room_type_detail.room_type.id) || {}).count === 0"
                >
                  <div class="not-available-label">Not Available</div>
                </template>

                <div
                  class="color-row mb-1"
                  style="
                    width: 50px;
                    height: 5px;
                    margin: 5px 0;
                    background-color: #265f39;
                  "
                ></div>
                <!-- Display features -->
                <div class="mb-2">
                  <strong>Facilities:</strong>
                  <div
                    class="ml-1 mb-2 mt-1"
                    v-for="(facility, j) in room_type_detail.room_type
                      .facilities"
                    :key="j"
                  >
                    <img
                      :src="facility.icon"
                      alt="Facility Icon"
                      class="mr-1"
                      style="height: 20px; width: 20px"
                      v-b-tooltip.hover
                      :title="facility.name"
                    />
                    <strong>{{ facility.name }}</strong>
                  </div>
                </div>
                <h5
                  class="d-flex justify-content-center"
                  style="color: #1c462a; font-weight: bold"
                >
                  <strong>Price:&nbsp;PKR&nbsp;</strong>
                  {{ room_type_detail.price }}
                </h5>
              </b-card-body>
            </b-col>
          </b-row>
        </div>

        <div class="d-flex justify-content-center">
        <b-button
              id="book"
              type="book"
              variant="warning"
              pill
              class="mr-1 text-center"
              @click="alliedCharges"
            >
        Check Allied Charges
      </b-button>
           <b-button
              v-if="showBookButton"
              id="book"
              type="book"
              variant="primary"
              pill
              class="mr-1 text-center"
              @click="createBooking"
            >
        Book Now
      </b-button>
        </div>
      </b-col>
      <b-col md="2"> </b-col>
    </b-row>

    <NewRoomBook
      @modalClosed="onModalClosed"
      :roomTypes="messData.room_type_details"
      :messData="messData"
      :availableRooms="messData.no_of_available_rooms"
      :roomImages = "messData.room_images"
      :key="`edit-${bookingCreateModalCount}`"
    />
    <AlliedChargesModal
    @onmodalCloded="onModalClosed"
    :key="`view-${alliedChargesModalCount}`"/>
  </div>
</template>


    
    
<script>
import { mapActions, mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import flatPickr from "vue-flatpickr-component";
import { Carousel3d, Slide } from "vue-carousel-3d";
import moment from "moment";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import util from "@/util.js";
import "swiper/css/swiper.css";
import NewRoomBook from "@/components/booking/guesthouses/NewRoomBook.vue";
import AlliedChargesModal from "@/components/booking/admin/AlliedChargesModal.vue";

export default {
  components: {
    Carousel3d,
    Slide,
    VueApexCharts,
    VuePerfectScrollbar,
    flatPickr,
    Swiper,
    SwiperSlide,
    NewRoomBook,
    AlliedChargesModal
  },
  mixins: [util],
  data() {
    return {
      messData: {},
      bookingCreateModalCount: 0,
      alliedChargesModalCount: 0,
      showModal: false,
      isMounted: false,
    };
  },
  created() {},
  async mounted() {
    const { id } = this.$route.query;
    const storedFilters =
      JSON.parse(localStorage.getItem("globalFilters")) || {};
    if (Object.keys(storedFilters).length !== 0) {
      let tempParams = {};
      if ("date_from" in storedFilters) {
        tempParams["date_from"] = storedFilters.date_from;
      }
      if ("date_to" in storedFilters) {
        tempParams["date_to"] = storedFilters.date_to;
      }
      if ("city" in storedFilters) {
        tempParams["city"] = storedFilters.city;
      }
      if ("booking_for" in storedFilters) {
        tempParams["booking_for"] = storedFilters.booking_for;
      }
      tempParams["mess_id"] = id;
      let res = await this.getAvailableRooms(tempParams);
      if (res.status === 200) {
        this.messData = res.data;
      }
    }
    this.isMounted = true;
  },
  methods: {
    ...mapActions({
      getAvailableRooms: "appData/getAvailableRooms",
      getMess: "appData/getMess",
    }),
    async onModalClosed() {
      this.$bvModal.hide("booking-create-modal");
    },
    createBooking() {
      this.bookingCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("booking-create-modal");
      });
    },
    alliedCharges() {
      this.alliedChargesModalCount +=1;
      this.$nextTick(()=>{
        this.$bvModal.show("allied-charges-modal")
      })
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    bookRoom() {
      this.closeModal();
    },
    ...mapActions({
      // getDashboardData: "appData/getDashboardData"
    }),

    search() {
      //Search Button functionality
    },
    subscribe() {
      if (this.email) {
        alert(`Subscribed with email: ${this.email}`);
      } else {
        alert("Please enter a valid Email address.");
      }
    },

    async dashboardData() {
      try {
        // const res = await this.getDashboardData();
        // const res = await this.getDashboardData({
        //   from_session_year: this.fromSelectedSession.session_year,
        //   to_session_year: this.toSelectedSession.session_year,
        // });
      } catch (error) {
        this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
    }),
    showBookButton() {
      return this.messData.room_type_details.some(roomType => {
      const availableRoom = this.messData.no_of_available_rooms.rooms_by_type.find(room => room.room_type === roomType.room_type.id);
      return availableRoom && availableRoom.count > 0;
    });
  }
  },
  watch: {
    // toSelectedSession(newValue, oldValue) {
    //   if (newValue) {
    //     this.dashboardData();
    //   }
    // }
  },
};
</script>
    
  
    
  <style scoped>
.guesthouse-card {
  background: #4321;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
}
.w-90 {
  width: 99%;
}
.color-row {
  width: 50px;
  height: 5px;
  margin: 5px 0;
}
.position-relative {
  position: relative;
}

.not-available-label {
  position: absolute;
  border-radius: 2px;
  top: 0;
  right: 0;
  padding: 3px;
  background-color: rgb(199, 67, 50);
  color: white;
  font-weight: bold;
}
</style> 
    