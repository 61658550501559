<template>
  <b-modal
    id="allied-charges-modal"
    centered
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    dialog-class="my-dialog-class"
    hide-footer
  >
<div class="table-responsive">
    <div class="d-flex justify-content-center align-items-center flex-nowrap">
      <h2>
      <strong>
      ALLIED CHARGES (PER DAY)
      </strong>
      </h2>
    </div>

      <!-- <ul>
        <li>Guest room charges are inclusive of Electricity and Gas charges, details are:</li>
        </ul> -->
      <h3 class="mt-2"><feather-icon icon="AlertTriangleIcon" size="22" class="mr-1"/><strong>Guest room charges are inclusive of Electricity and Gas charges.</strong></h3>
    <p>
      <h3 class="mt-2"><feather-icon icon="AlertTriangleIcon" size="22" class="mr-1"/><strong>However, where separate meters are installed, Electricity and Gas charges will be levied as per actual consumption after duly adjusting under mentioned amounts :-</strong></h3>
      <ul class="pl-5">
        <li><h4>Offrs (Serving & Retd) - Std Room @ Rs 500 per Day</h4></li>
        <li><h4>Offrs (Serving & Retd) - One and Two Room Suites @ Rs 1,000 per Day</h4></li>
        <li><h4>Civil Guests - Std Room @ Rs 1,000 per Day</h4></li>
        <li><h4>Civil Guests - One and Two Room Suites @ Rs 1,500 per Day</h4></li>
      </ul>
    </p>
      <h3 class="mt-2"><feather-icon icon="AlertTriangleIcon" size="22" class="mr-1"/><strong>Late Check-out charges will be levied @ Rs 1,000 per hour; max upto 1500 hours.</strong></h3>
      <h3 class="mt-2"><feather-icon icon="AlertTriangleIcon" size="22" class="mr-1"/><strong>Toiletries, amenities and additional facilities will be charged separately (if and when applicable).</strong></h3>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import util from "@/util.js";
import moment from "moment";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  mixins: [util, togglePasswordVisibility],

  data() {
    return {
     
    };
  },
  async mounted() {
  },



};
</script>
<style scoped>
.my-dialog-class .tablee {
  width: 100%;
  border-collapse: collapse;
}

.my-dialog-class .table-bordered th,
.my-dialog-class .table-bordered td {
  border: 1px solid #dee2e6;
}

.my-dialog-class .tablee thead th {
  background-color: #f8f9fa;
  color: #495057;
  border-color: #dee2e6;
  text-align: center;
  vertical-align: middle;
}

.my-dialog-class .tablee tbody td {
  text-align: center;
  vertical-align: middle;
}

.my-dialog-class .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.my-dialog-class .tablee tbody tr:nth-of-type(even) {
  background-color: #f2f2f2;
}

.my-dialog-class .tablee tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}
  .reduce-space {
    font-size: 13px; 
    max-width: 120px; 
    white-space: wrap;
  }
</style>

